footer {
  padding: 6rem 0;

  p {
    font-size: 1.8rem;
    color: $fourth-color;
  }

  h4 {
    font-size: 2.5rem;
    color: $third-color;
    margin-bottom: 3rem;
  }

  a {
    color: $fourth-color;
    transition: 1s ease-in-out;
    display: block;

    &:not(:last-child) {
      padding-bottom: 3rem;
    }

    &:hover {
      color: $first-color;
    }
  }

  .footer__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      flex: 0 0 30%;

      //   &:not(:first-child) {
      //     padding-left: 12rem;
      //   }
    }

    .footer--service {
      h4 {
        margin-bottom: 2rem;
      }

      //   &:not(:last-child) {
      //     padding-bottom: 2.5rem;
      //   }
    }
  }
}

.footer--bg {
  background: $fifth-color;
}

@media screen and (max-width: 600px) {
  footer {
    .footer__wrap {
      div {
        flex-basis: 100%;

        &:not(:first-child) {
          padding-top: 4rem;
        }
      }
    }
  }
}
